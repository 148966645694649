import * as types from '../actionTypes';

const initialState = {
  aboutApp: {},
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_ABOUT_APP_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_ABOUT_APP_SUCCESS:
      return {...state, aboutApp: action.data, loading: false, error: null};
    case types.FETCH_ABOUT_APP_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.CREATE_ABOUT_APP_REQUEST:
      return {...state, loading: true, error: null};
    case types.CREATE_ABOUT_APP_SUCCESS:
      return {...state, loading: false, error: null};
    case types.CREATE_ABOUT_APP_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
