import * as types from '../actionTypes';

const initialState = {
  token: '',
  lang: 'ru',
  account: null,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.CHANGE_LANGUAGE:
      return {...state, lang: action.lang};

    case types.LOGIN_REQUEST:
      return {...state, loading: true, error: null};
    case types.LOGIN_SUCCESS:
      return {...state, account: action.data, token: action.token, loading: false, error: null};
    case types.LOGIN_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.LOGOUT_REQUEST:
      return {...state, loading: true, error: null};
    case types.LOGOUT_SUCCESS:
      return {...initialState};
    case types.LOGOUT_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.REFRESH_TOKEN_REQUEST:
      return {...state, loading: true, error: null};
    case types.REFRESH_TOKEN_SUCCESS:
      return {...state, account: action.data, token: action.token, loading: false, error: null};
    case types.REFRESH_TOKEN_FAILURE:
      return {...state, account: null, token: '', loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
