export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export const FETCH_ACCESS_LIST_REQUEST = 'FETCH_ACCESS_LIST_REQUEST';
export const FETCH_ACCESS_LIST_SUCCESS = 'FETCH_ACCESS_LIST_SUCCESS';
export const FETCH_ACCESS_LIST_FAILURE = 'FETCH_ACCESS_LIST_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

// Users

export const FETCH_ADMINISTRATORS_LIST_REQUEST = 'FETCH_ADMINISTRATORS_LIST_REQUEST';
export const FETCH_ADMINISTRATORS_LIST_SUCCESS = 'FETCH_ADMINISTRATORS_LIST_SUCCESS';
export const FETCH_ADMINISTRATORS_LIST_FAILURE = 'FETCH_ADMINISTRATORS_LIST_FAILURE';

export const CREATE_ADMINISTRATORS_ITEM_REQUEST = 'CREATE_ADMINISTRATORS_ITEM_REQUEST';
export const CREATE_ADMINISTRATORS_ITEM_SUCCESS = 'CREATE_ADMINISTRATORS_ITEM_SUCCESS';
export const CREATE_ADMINISTRATORS_ITEM_FAILURE = 'CREATE_ADMINISTRATORS_ITEM_FAILURE';

export const READ_ADMINISTRATORS_ITEM_REQUEST = 'READ_ADMINISTRATORS_ITEM_REQUEST';
export const READ_ADMINISTRATORS_ITEM_SUCCESS = 'READ_ADMINISTRATORS_ITEM_SUCCESS';
export const READ_ADMINISTRATORS_ITEM_FAILURE = 'READ_ADMINISTRATORS_ITEM_FAILURE';

export const UPDATE_ADMINISTRATORS_ITEM_REQUEST = 'UPDATE_ADMINISTRATORS_ITEM_REQUEST';
export const UPDATE_ADMINISTRATORS_ITEM_SUCCESS = 'UPDATE_ADMINISTRATORS_ITEM_SUCCESS';
export const UPDATE_ADMINISTRATORS_ITEM_FAILURE = 'UPDATE_ADMINISTRATORS_ITEM_FAILURE';

export const DELETE_ADMINISTRATORS_ITEM_REQUEST = 'DELETE_ADMINISTRATORS_ITEM_REQUEST';
export const DELETE_ADMINISTRATORS_ITEM_SUCCESS = 'DELETE_ADMINISTRATORS_ITEM_SUCCESS';
export const DELETE_ADMINISTRATORS_ITEM_FAILURE = 'DELETE_ADMINISTRATORS_ITEM_FAILURE';

export const FETCH_USERS_LIST_REQUEST = 'FETCH_USERS_LIST_REQUEST';
export const FETCH_USERS_LIST_SUCCESS = 'FETCH_USERS_LIST_SUCCESS';
export const FETCH_USERS_LIST_FAILURE = 'FETCH_USERS_LIST_FAILURE';

export const READ_USERS_ITEM_REQUEST = 'READ_USERS_ITEM_REQUEST';
export const READ_USERS_ITEM_SUCCESS = 'READ_USERS_ITEM_SUCCESS';
export const READ_USERS_ITEM_FAILURE = 'READ_USERS_ITEM_FAILURE';

export const UPDATE_USERS_ITEM_REQUEST = 'UPDATE_USERS_ITEM_REQUEST';
export const UPDATE_USERS_ITEM_SUCCESS = 'UPDATE_USERS_ITEM_SUCCESS';
export const UPDATE_USERS_ITEM_FAILURE = 'UPDATE_USERS_ITEM_FAILURE';

export const DELETE_USERS_ITEM_REQUEST = 'DELETE_USERS_ITEM_REQUEST';
export const DELETE_USERS_ITEM_SUCCESS = 'DELETE_USERS_ITEM_SUCCESS';
export const DELETE_USERS_ITEM_FAILURE = 'DELETE_USERS_ITEM_FAILURE';

export const DELETE_MANY_USERS_ITEMS_REQUEST = 'DELETE_MANY_USERS_ITEMS_REQUEST';
export const DELETE_MANY_USERS_ITEMS_SUCCESS = 'DELETE_MANY_USERS_ITEMS_SUCCESS';
export const DELETE_MANY_USERS_ITEMS_FAILURE = 'DELETE_MANY_USERS_ITEMS_FAILURE';

export const FETCH_ROLES_LIST_REQUEST = 'FETCH_ROLES_LIST_REQUEST';
export const FETCH_ROLES_LIST_SUCCESS = 'FETCH_ROLES_LIST_SUCCESS';
export const FETCH_ROLES_LIST_FAILURE = 'FETCH_ROLES_LIST_FAILURE';

export const CREATE_ROLES_ITEM_REQUEST = 'CREATE_ROLES_ITEM_REQUEST';
export const CREATE_ROLES_ITEM_SUCCESS = 'CREATE_ROLES_ITEM_SUCCESS';
export const CREATE_ROLES_ITEM_FAILURE = 'CREATE_ROLES_ITEM_FAILURE';

export const UPDATE_ROLES_ITEM_REQUEST = 'UPDATE_ROLES_ITEM_REQUEST';
export const UPDATE_ROLES_ITEM_SUCCESS = 'UPDATE_ROLES_ITEM_SUCCESS';
export const UPDATE_ROLES_ITEM_FAILURE = 'UPDATE_ROLES_ITEM_FAILURE';

export const FETCH_DISTRIBUTION_GROUPS_LIST_REQUEST = 'FETCH_DISTRIBUTION_GROUPS_LIST_REQUEST';
export const FETCH_DISTRIBUTION_GROUPS_LIST_SUCCESS = 'FETCH_DISTRIBUTION_GROUPS_LIST_SUCCESS';
export const FETCH_DISTRIBUTION_GROUPS_LIST_FAILURE = 'FETCH_DISTRIBUTION_GROUPS_LIST_FAILURE';

export const CREATE_DISTRIBUTION_GROUPS_ITEM_REQUEST = 'CREATE_DISTRIBUTION_GROUPS_ITEM_REQUEST';
export const CREATE_DISTRIBUTION_GROUPS_ITEM_SUCCESS = 'CREATE_DISTRIBUTION_GROUPS_ITEM_SUCCESS';
export const CREATE_DISTRIBUTION_GROUPS_ITEM_FAILURE = 'CREATE_DISTRIBUTION_GROUPS_ITEM_FAILURE';

export const DELETE_DISTRIBUTION_GROUPS_ITEM_REQUEST = 'DELETE_DISTRIBUTION_GROUPS_ITEM_REQUEST';
export const DELETE_DISTRIBUTION_GROUPS_ITEM_SUCCESS = 'DELETE_DISTRIBUTION_GROUPS_ITEM_SUCCESS';
export const DELETE_DISTRIBUTION_GROUPS_ITEM_FAILURE = 'DELETE_DISTRIBUTION_GROUPS_ITEM_FAILURE';

// News

export const FETCH_NEWS_LIST_REQUEST = 'FETCH_NEWS_LIST_REQUEST';
export const FETCH_NEWS_LIST_SUCCESS = 'FETCH_NEWS_LIST_SUCCESS';
export const FETCH_NEWS_LIST_FAILURE = 'FETCH_NEWS_LIST_FAILURE';

export const READ_NEWS_ITEM_REQUEST = 'READ_NEWS_ITEM_REQUEST';
export const READ_NEWS_ITEM_SUCCESS = 'READ_NEWS_ITEM_SUCCESS';
export const READ_NEWS_ITEM_FAILURE = 'READ_NEWS_ITEM_FAILURE';

export const CREATE_NEWS_ITEM_REQUEST = 'CREATE_NEWS_ITEM_REQUEST';
export const CREATE_NEWS_ITEM_SUCCESS = 'CREATE_NEWS_ITEM_SUCCESS';
export const CREATE_NEWS_ITEM_FAILURE = 'CREATE_NEWS_ITEM_FAILURE';

export const UPDATE_NEWS_ITEM_REQUEST = 'UPDATE_NEWS_ITEM_REQUEST';
export const UPDATE_NEWS_ITEM_SUCCESS = 'UPDATE_NEWS_ITEM_SUCCESS';
export const UPDATE_NEWS_ITEM_FAILURE = 'UPDATE_NEWS_ITEM_FAILURE';

export const DELETE_NEWS_ITEM_REQUEST = 'DELETE_NEWS_ITEM_REQUEST';
export const DELETE_NEWS_ITEM_SUCCESS = 'DELETE_NEWS_ITEM_SUCCESS';
export const DELETE_NEWS_ITEM_FAILURE = 'DELETE_NEWS_ITEM_FAILURE';

export const FETCH_KGM_EVENTS_LIST_REQUEST = 'FETCH_KGM_EVENTS_LIST_REQUEST';
export const FETCH_KGM_EVENTS_LIST_SUCCESS = 'FETCH_KGM_EVENTS_LIST_SUCCESS';
export const FETCH_KGM_EVENTS_LIST_FAILURE = 'FETCH_KGM_EVENTS_LIST_FAILURE';

export const FETCH_IMPORTANT_ALERTS_LIST_REQUEST = 'FETCH_IMPORTANT_ALERTS_LIST_REQUEST';
export const FETCH_IMPORTANT_ALERTS_LIST_SUCCESS = 'FETCH_IMPORTANT_ALERTS_LIST_SUCCESS';
export const FETCH_IMPORTANT_ALERTS_LIST_FAILURE = 'FETCH_IMPORTANT_ALERTS_LIST_FAILURE';

export const READ_IMPORTANT_ALERTS_ITEM_REQUEST = 'READ_IMPORTANT_ALERTS_ITEM_REQUEST';
export const READ_IMPORTANT_ALERTS_ITEM_SUCCESS = 'READ_IMPORTANT_ALERTS_ITEM_SUCCESS';
export const READ_IMPORTANT_ALERTS_ITEM_FAILURE = 'READ_IMPORTANT_ALERTS_ITEM_FAILURE';

export const CREATE_IMPORTANT_ALERTS_ITEM_REQUEST = 'CREATE_IMPORTANT_ALERTS_ITEM_REQUEST';
export const CREATE_IMPORTANT_ALERTS_ITEM_SUCCESS = 'CREATE_IMPORTANT_ALERTS_ITEM_SUCCESS';
export const CREATE_IMPORTANT_ALERTS_ITEM_FAILURE = 'CREATE_IMPORTANT_ALERTS_ITEM_FAILURE';

export const UPDATE_IMPORTANT_ALERTS_ITEM_REQUEST = 'UPDATE_IMPORTANT_ALERTS_ITEM_REQUEST';
export const UPDATE_IMPORTANT_ALERTS_ITEM_SUCCESS = 'UPDATE_IMPORTANT_ALERTS_ITEM_SUCCESS';
export const UPDATE_IMPORTANT_ALERTS_ITEM_FAILURE = 'UPDATE_IMPORTANT_ALERTS_ITEM_FAILURE';

export const DELETE_IMPORTANT_ALERTS_ITEM_REQUEST = 'DELETE_IMPORTANT_ALERTS_ITEM_REQUEST';
export const DELETE_IMPORTANT_ALERTS_ITEM_SUCCESS = 'DELETE_IMPORTANT_ALERTS_ITEM_SUCCESS';
export const DELETE_IMPORTANT_ALERTS_ITEM_FAILURE = 'DELETE_IMPORTANT_ALERTS_ITEM_FAILURE';

// Guides

export const FETCH_GUIDES_LIST_REQUEST = 'FETCH_GUIDES_LIST_REQUEST';
export const FETCH_GUIDES_LIST_SUCCESS = 'FETCH_GUIDES_LIST_SUCCESS';
export const FETCH_GUIDES_LIST_FAILURE = 'FETCH_GUIDES_LIST_FAILURE';

export const READ_GUIDES_ITEM_REQUEST = 'READ_GUIDES_ITEM_REQUEST';
export const READ_GUIDES_ITEM_SUCCESS = 'READ_GUIDES_ITEM_SUCCESS';
export const READ_GUIDES_ITEM_FAILURE = 'READ_GUIDES_ITEM_FAILURE';

export const CREATE_GUIDES_ITEM_REQUEST = 'CREATE_GUIDES_ITEM_REQUEST';
export const CREATE_GUIDES_ITEM_SUCCESS = 'CREATE_GUIDES_ITEM_SUCCESS';
export const CREATE_GUIDES_ITEM_FAILURE = 'CREATE_GUIDES_ITEM_FAILURE';

export const UPDATE_GUIDES_ITEM_REQUEST = 'UPDATE_GUIDES_ITEM_REQUEST';
export const UPDATE_GUIDES_ITEM_SUCCESS = 'UPDATE_GUIDES_ITEM_SUCCESS';
export const UPDATE_GUIDES_ITEM_FAILURE = 'UPDATE_GUIDES_ITEM_FAILURE';

export const DELETE_GUIDES_ITEM_REQUEST = 'DELETE_GUIDES_ITEM_REQUEST';
export const DELETE_GUIDES_ITEM_SUCCESS = 'DELETE_GUIDES_ITEM_SUCCESS';
export const DELETE_GUIDES_ITEM_FAILURE = 'DELETE_GUIDES_ITEM_FAILURE';

// About

export const FETCH_ABOUT_APP_REQUEST = 'FETCH_ABOUT_APP_REQUEST';
export const FETCH_ABOUT_APP_SUCCESS = 'FETCH_ABOUT_APP_SUCCESS';
export const FETCH_ABOUT_APP_FAILURE = 'FETCH_ABOUT_APP_FAILURE';

export const CREATE_ABOUT_APP_REQUEST = 'CREATE_ABOUT_APP_REQUEST';
export const CREATE_ABOUT_APP_SUCCESS = 'CREATE_ABOUT_APP_SUCCESS';
export const CREATE_ABOUT_APP_FAILURE = 'CREATE_ABOUT_APP_FAILURE';

// Maps

export const FETCH_MAP_POLYGONS_REQUEST = 'FETCH_MAP_POLYGONS_REQUEST';
export const FETCH_MAP_POLYGONS_SUCCESS = 'FETCH_MAP_POLYGONS_SUCCESS';
export const FETCH_MAP_POLYGONS_FAILURE = 'FETCH_MAP_POLYGONS_FAILURE';

export const FETCH_GEO_DATA_REQUEST = 'FETCH_GEO_DATA_REQUEST';
export const FETCH_GEO_DATA_SUCCESS = 'FETCH_GEO_DATA_SUCCESS';
export const FETCH_GEO_DATA_FAILURE = 'FETCH_GEO_DATA_FAILURE';

// Additional services

export const FETCH_COVID19_LIST_REQUEST = 'FETCH_COVID19_LIST_REQUEST';
export const FETCH_COVID19_LIST_SUCCESS = 'FETCH_COVID19_LIST_SUCCESS';
export const FETCH_COVID19_LIST_FAILURE = 'FETCH_COVID19_LIST_FAILURE';

export const CREATE_COVID19_ENTRY_REQUEST = 'CREATE_COVID19_ENTRY_REQUEST';
export const CREATE_COVID19_ENTRY_SUCCESS = 'CREATE_COVID19_ENTRY_SUCCESS';
export const CREATE_COVID19_ENTRY_FAILURE = 'CREATE_COVID19_ENTRY_FAILURE';

export const UPDATE_COVID19_ENTRY_REQUEST = 'UPDATE_COVID19_ENTRY_REQUEST';
export const UPDATE_COVID19_ENTRY_SUCCESS = 'UPDATE_COVID19_ENTRY_SUCCESS';
export const UPDATE_COVID19_ENTRY_FAILURE = 'UPDATE_COVID19_ENTRY_FAILURE';

export const DELETE_COVID19_ENTRY_REQUEST = 'DELETE_COVID19_ENTRY_REQUEST';
export const DELETE_COVID19_ENTRY_SUCCESS = 'DELETE_COVID19_ENTRY_SUCCESS';
export const DELETE_COVID19_ENTRY_FAILURE = 'DELETE_COVID19_ENTRY_FAILURE';

export const FETCH_COVID19_TITLES_REQUEST = 'FETCH_COVID19_TITLES_REQUEST';
export const FETCH_COVID19_TITLES_SUCCESS = 'FETCH_COVID19_TITLES_SUCCESS';
export const FETCH_COVID19_TITLES_FAILURE = 'FETCH_COVID19_TITLES_FAILURE';

export const UPDATE_COVID19_TITLES_REQUEST = 'UPDATE_COVID19_TITLES_REQUEST';
export const UPDATE_COVID19_TITLES_SUCCESS = 'UPDATE_COVID19_TITLES_SUCCESS';
export const UPDATE_COVID19_TITLES_FAILURE = 'UPDATE_COVID19_TITLES_FAILURE';

// IAMS

export const FETCH_REGIONS_LIST_REQUEST = 'FETCH_REGIONS_LIST_REQUEST';
export const FETCH_REGIONS_LIST_SUCCESS = 'FETCH_REGIONS_LIST_SUCCESS';
export const FETCH_REGIONS_LIST_FAILURE = 'FETCH_REGIONS_LIST_FAILURE';

export const FETCH_DISTRICTS_LIST_REQUEST = 'FETCH_DISTRICTS_LIST_REQUEST';
export const FETCH_DISTRICTS_LIST_SUCCESS = 'FETCH_DISTRICTS_LIST_SUCCESS';
export const FETCH_DISTRICTS_LIST_FAILURE = 'FETCH_DISTRICTS_LIST_FAILURE';

export const FETCH_ANALYTICS_DATA_REQUEST = 'FETCH_ANALYTICS_DATA_REQUEST';
export const FETCH_ANALYTICS_DATA_SUCCESS = 'FETCH_ANALYTICS_DATA_SUCCESS';
export const FETCH_ANALYTICS_DATA_FAILURE = 'FETCH_ANALYTICS_DATA_FAILURE';

export const FETCH_STATISTICS_DATA_REQUEST = 'FETCH_STATISTICS_DATA_REQUEST';
export const FETCH_STATISTICS_DATA_SUCCESS = 'FETCH_STATISTICS_DATA_SUCCESS';
export const FETCH_STATISTICS_DATA_FAILURE = 'FETCH_STATISTICS_DATA_FAILURE';
