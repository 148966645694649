import thunkMiddleware from "redux-thunk";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {routerMiddleware, routerReducer} from "react-router-redux";
import {createBrowserHistory} from "history";

import {saveState, loadState, savePolygons} from "./localStorage";

import authReducer from "./reducers/auth";
import accessReducer from "./reducers/access";
import administratorsReducer from "./reducers/users/administrators";
import usersReducer from "./reducers/users/users";
import rolesReducer from "./reducers/users/roles";
import distributionGroupsReducer from "./reducers/users/distributionGroups";
import newsReducer from "./reducers/news";
import importantAlertsReducer from "./reducers/importantAlerts";
import guidesReducer from "./reducers/guides";
import aboutAppReducer from "./reducers/aboutApp";
import covid19Reducer from "./reducers/additionalServices/covid19";
import regionsReducer from "./reducers/iams/regions";
import analyticsReducer from "./reducers/iams/analytics";
import defaultPolygonsReducer from "./reducers/defaultPolygons";

const rootReducer = combineReducers({
  auth: authReducer,
  access: accessReducer,
  administrators: administratorsReducer,
  users: usersReducer,
  roles: rolesReducer,
  distributionGroups: distributionGroupsReducer,
  news: newsReducer,
  importantAlerts: importantAlertsReducer,
  guides: guidesReducer,
  aboutApp: aboutAppReducer,
  covid19: covid19Reducer,
  regions: regionsReducer,
  analytics: analyticsReducer,
  defaultPolygons: defaultPolygonsReducer,
  routing: routerReducer
});

export const history = createBrowserHistory();

const middleware = [
  thunkMiddleware,
  routerMiddleware(history)
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(applyMiddleware(...middleware));

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
  });
  savePolygons({
    defaultPolygons: store.getState().defaultPolygons
  });
});

export default store;
