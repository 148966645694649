import * as types from '../../actionTypes';

const initialState = {
  usersList: [],
  usersItem: {},
  loading: false,
  itemLoading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_USERS_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_USERS_LIST_SUCCESS:
      return {...state, usersList: action.data, loading: false, error: null};
    case types.FETCH_USERS_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.READ_USERS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.READ_USERS_ITEM_SUCCESS:
      return {...state, usersItem: action.data, itemLoading: false, error: null};
    case types.READ_USERS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.UPDATE_USERS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.UPDATE_USERS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.UPDATE_USERS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.DELETE_USERS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.DELETE_USERS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.DELETE_USERS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.DELETE_MANY_USERS_ITEMS_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.DELETE_MANY_USERS_ITEMS_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.DELETE_MANY_USERS_ITEMS_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
