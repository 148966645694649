import * as types from '../../actionTypes';

const initialState = {
  regionsList: [],
  districtsList: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_REGIONS_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_REGIONS_LIST_SUCCESS:
      return {...state, regionsList: action.data, districtsList: [], loading: false, error: null};
    case types.FETCH_REGIONS_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.FETCH_DISTRICTS_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_DISTRICTS_LIST_SUCCESS:
      return {...state, districtsList: action.data, loading: false, error: null};
    case types.FETCH_DISTRICTS_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
