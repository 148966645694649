import axios from '../../axios';
import * as types from '../actionTypes';

const fetchAccessListRequest = () => ({type: types.FETCH_ACCESS_LIST_REQUEST});
const fetchAccessListSuccess = (data, token) => ({type: types.FETCH_ACCESS_LIST_SUCCESS, data, token});
const fetchAccessListFailure = error => ({type: types.FETCH_ACCESS_LIST_FAILURE, error});

export const fetchAccessList = (onSuccess, onError) => {
  return dispatch => {
    dispatch(fetchAccessListRequest());
    return axios.get('/auth/access-list').then(
      response => {
        dispatch(fetchAccessListSuccess(response.data));
        try { onSuccess() } catch (e) {}
      },
      error => {
        dispatch(fetchAccessListFailure(error));
        try { onError() } catch (e) {}
      }
    )
  }
};
