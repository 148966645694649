import axios from '../../axios';
import * as types from '../actionTypes';

export const changeLanguageSuccess = lang => ({type: types.CHANGE_LANGUAGE, lang});

export const changeLanguage = (lang, onSuccess, onError) => {
  return dispatch => {
    return axios.put('/auth/change-lang', {language: lang}).then(
      response => {
        dispatch(changeLanguageSuccess(lang));
        try { onSuccess() } catch (e) {}
      },
      error => {
        try { onError() } catch (e) {}
      }
    )
  }
};

const setPermissions = data => ({type: types.SET_PERMISSIONS, data});

const loginRequest = () => ({type: types.LOGIN_REQUEST});
const loginSuccess = (data, token) => ({type: types.LOGIN_SUCCESS, data, token});
const loginFailure = error => ({type: types.LOGIN_FAILURE, error});

export const login = (data, onSuccess, onError) => {
  return dispatch => {
    dispatch(loginRequest());
    return axios.post('/auth/login', data).then(
      response => {
        dispatch(loginSuccess(response.data.data, response.data.token || ''));
        dispatch(setPermissions(response.data.permissions || []));
        try { onSuccess() } catch (e) {}
      },
      error => {
        dispatch(loginFailure(error));
        try { onError() } catch (e) {}
      }
    )
  }
};

const logoutRequest = () => ({type: types.LOGOUT_REQUEST});
const logoutSuccess = () => ({type: types.LOGOUT_SUCCESS});
const logoutFailure = error => ({type: types.LOGOUT_FAILURE, error});

export const logout = (onSuccess, onError) => {
  return dispatch => {
    dispatch(logoutRequest());
    return axios.get('/auth/logout').then(
      response => {
        dispatch(logoutSuccess());
        try { onSuccess() } catch (e) {}
      },
      error => {
        dispatch(logoutFailure(error));
        try { onError() } catch (e) {}
      }
    )
  }
};

const refreshTokenRequest = () => ({type: types.REFRESH_TOKEN_REQUEST});
const refreshTokenSuccess = (data, token) => ({type: types.REFRESH_TOKEN_SUCCESS, data, token});
const refreshTokenFailure = () => ({type: types.REFRESH_TOKEN_FAILURE});

export const refreshToken = (onSuccess, onError) => {
  return dispatch => {
    dispatch(refreshTokenRequest());
    return axios.get('/auth/refresh').then(
      response => {
        dispatch(refreshTokenSuccess(response.data.data, response.data.token || ''));
        dispatch(setPermissions(response.data.permissions || []));
        try { onSuccess() } catch (e) {}
      },
      error => {
        dispatch(refreshTokenFailure(error));
        try { onError() } catch (e) {}
      }
    )
  }
};
