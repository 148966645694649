import * as types from '../../actionTypes';

const initialState = {
  analytics: [],
  statistics: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_ANALYTICS_DATA_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_ANALYTICS_DATA_SUCCESS:
      return {...state, analytics: action.data, statistics: [], loading: false, error: null};
    case types.FETCH_ANALYTICS_DATA_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.FETCH_STATISTICS_DATA_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_STATISTICS_DATA_SUCCESS:
      return {...state, statistics: action.data, analytics: [], loading: false, error: null};
    case types.FETCH_STATISTICS_DATA_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
