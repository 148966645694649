export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('IAMS-mobile', serializedState);
  } catch (e) {
    console.log('Could not save state');
  }
};

export const savePolygons = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('polygons', serializedState);
  } catch (e) {
    console.log('Could not save Polygons');
  }
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('IAMS-mobile');
    const polygons = localStorage.getItem('polygons');
    if (!serializedState) {
      return undefined;
    }
    if (!polygons) {
      return {...JSON.parse(serializedState)};
    }

    return {...JSON.parse(serializedState), ...JSON.parse(polygons)};
  } catch (e) {
    return undefined;
  }
};
