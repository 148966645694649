import * as types from '../../actionTypes';

const initialState = {
  groupsList: [],
  loading: false,
  itemLoading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_DISTRIBUTION_GROUPS_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_DISTRIBUTION_GROUPS_LIST_SUCCESS:
      return {...state, groupsList: action.data, loading: false, error: null};
    case types.FETCH_DISTRIBUTION_GROUPS_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.CREATE_DISTRIBUTION_GROUPS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.CREATE_DISTRIBUTION_GROUPS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.CREATE_DISTRIBUTION_GROUPS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.DELETE_DISTRIBUTION_GROUPS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.DELETE_DISTRIBUTION_GROUPS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.DELETE_DISTRIBUTION_GROUPS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
