import * as types from '../../actionTypes';
const initialState = {
  covid19Titles: {},
  covid19List: [],
  loading: false,
  loadingTitles: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_COVID19_TITLES_REQUEST:
      return {...state, loadingTitles: true, error: null};
    case types.FETCH_COVID19_TITLES_SUCCESS:
      return {...state, covid19Titles: action.data, loadingTitles: false, error: null};
    case types.FETCH_COVID19_TITLES_FAILURE:
      return {...state, loadingTitles: false, error: action.error};

    case types.UPDATE_COVID19_TITLES_REQUEST:
      return {...state, loadingTitles: true, error: null};
    case types.UPDATE_COVID19_TITLES_SUCCESS:
      return {...state, loadingTitles: false, error: null};
    case types.UPDATE_COVID19_TITLES_FAILURE:
      return {...state, loadingTitles: false, error: action.error};

    case types.FETCH_COVID19_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_COVID19_LIST_SUCCESS:
      return {...state, covid19List: action.data, loading: false, error: null};
    case types.FETCH_COVID19_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.CREATE_COVID19_ENTRY_REQUEST:
      return {...state, error: null};
    case types.CREATE_COVID19_ENTRY_SUCCESS:
      return {...state, error: null};
    case types.CREATE_COVID19_ENTRY_FAILURE:
      return {...state, error: action.error};

    case types.UPDATE_COVID19_ENTRY_REQUEST:
      return {...state, error: null};
    case types.UPDATE_COVID19_ENTRY_SUCCESS:
      return {...state, error: null};
    case types.UPDATE_COVID19_ENTRY_FAILURE:
      return {...state, error: action.error};

    case types.DELETE_COVID19_ENTRY_REQUEST:
      return {...state, error: null};
    case types.DELETE_COVID19_ENTRY_SUCCESS:
      return {...state, error: null};
    case types.DELETE_COVID19_ENTRY_FAILURE:
      return {...state, error: action.error};

    default:
      return state;
  }
};

export default reducer;
