import React, { Component } from 'react';
import {connect} from "react-redux";
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import './App.scss';

import AuthPreloader from "./components/UI/Preloader/AuthPreloader";

import {changeLanguageSuccess, refreshToken} from "./store/actions/auth";
import {fetchAccessList} from "./store/actions/access";

const Login = React.lazy(() => import('./pages/Login'));
const Covid19Map = React.lazy(() => import('./containers/AdditionalServices/Covid19Map'));
const Hydromet = React.lazy(() => import('./containers/Hydromet/Hydromet'));
const Analytics = React.lazy(() => import('./containers/Analytics/Analytics'));
const Statistics = React.lazy(() => import('./containers/Analytics/Statistics'));
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Page404 = React.lazy(() => import('./pages/Page404'));

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends Component {
  componentDidMount() {
    this.props.onChangeLanguageSuccess(this.props.lang || 'ru');
    this.props.i18n.changeLanguage(this.props.lang || 'ru');
    this.props.onRefreshToken();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.authLoading && this.props.authLoading !== nextProps.authLoading && nextProps.token) {
      this.props.onFetchAccessList();
      return false;
    }

    if (!nextProps.accessError && this.props.accessError) {
      return false;
    }

    if (nextProps.accessError) {
      setTimeout(() => this.props.onFetchAccessList(), 1000);
      return false;
    }

    return true;
  }

  render() {
    if (this.props.authLoading || this.props.accessListLoading) return <AuthPreloader/>;
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login" render={props => <Login {...props}/>}/>
            <Route exact path="/covid19-map" name="COVID-19 map"
                   render={props => <Covid19Map {...this.props} {...props}/>}/>
            <Route exact path="/hydromet" name="Hydromet"
                   render={props => <Hydromet {...this.props} {...props}/>}/>
            <Route exact path="/pub-analytics" name="Analytics"
                   render={props => <Analytics {...this.props} {...props}/>}/>
            <Route exact path="/pub-statistics" name="Statistics"
                   render={props => <Statistics {...this.props} {...props}/>}/>
            {this.props.token ?
              <Route path="/" name="Home" render={props => <DefaultLayout {...this.props} {...props}/>}/>
              :
              <Redirect from="/" to="/login"/>
            }
            <Route path="/" name="Page404" render={props => <Page404 {...this.props} {...props}/>}/>
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.auth.lang,
  token: state.auth.token,
  account: state.auth.account,
  authLoading: state.auth.loading,
  permissions: state.access.permissions,
  accessList: state.access.accessList,
  accessListLoading: state.access.loading,
  accessError: state.access.error
});

const mapDispatchToProps = dispatch => ({
  onChangeLanguageSuccess: lang => dispatch(changeLanguageSuccess(lang)),
  onRefreshToken: (onSuccess, onError) => dispatch(refreshToken(onSuccess, onError)),
  onFetchAccessList: () => dispatch(fetchAccessList())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
