import * as types from '../actionTypes';

const initialState = {
  geoData: {},
  polygons: [],
  timestamp: 0,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_GEO_DATA_SUCCESS:
      return {...state, geoData: action.data};

    case types.FETCH_MAP_POLYGONS_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_MAP_POLYGONS_SUCCESS:
      return {...state, polygons: action.data, timestamp: Date.now(), loading: false, error: null};
    case types.FETCH_MAP_POLYGONS_FAILURE:
      return {...state, loading: false, timestamp: 0, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
