import * as types from '../actionTypes';

const initialState = {
  kgmEventsList: [],
  awarenessLevels: [],
  awarenessTypes: [],
  importantAlertsList: [],
  importantAlertsItem: {},
  loading: false,
  itemLoading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_KGM_EVENTS_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_KGM_EVENTS_LIST_SUCCESS:
      return {...state,
        kgmEventsList: action.alerts,
        awarenessLevels: action.awarenessLevels,
        awarenessTypes: action.awarenessTypes,
        loading: false,
        error: null
      };
    case types.FETCH_KGM_EVENTS_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.FETCH_IMPORTANT_ALERTS_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_IMPORTANT_ALERTS_LIST_SUCCESS:
      return {...state, importantAlertsList: action.data, loading: false, error: null};
    case types.FETCH_IMPORTANT_ALERTS_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.READ_IMPORTANT_ALERTS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.READ_IMPORTANT_ALERTS_ITEM_SUCCESS:
      return {...state, importantAlertsItem: action.data, itemLoading: false, error: null};
    case types.READ_IMPORTANT_ALERTS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.CREATE_IMPORTANT_ALERTS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.CREATE_IMPORTANT_ALERTS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.CREATE_IMPORTANT_ALERTS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.UPDATE_IMPORTANT_ALERTS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.UPDATE_IMPORTANT_ALERTS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.UPDATE_IMPORTANT_ALERTS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.DELETE_IMPORTANT_ALERTS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.DELETE_IMPORTANT_ALERTS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.DELETE_IMPORTANT_ALERTS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
