import * as types from '../actionTypes';

const initialState = {
  permissions: [],
  accessList: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.SET_PERMISSIONS:
      return {...state, permissions: action.data};

    case types.FETCH_ACCESS_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_ACCESS_LIST_SUCCESS:
      return {...state, accessList: action.data, loading: false, error: null};
    case types.FETCH_ACCESS_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
