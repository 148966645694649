import * as types from '../actionTypes';

const initialState = {
  newsList: [],
  newsItem: {},
  loading: false,
  itemLoading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_NEWS_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_NEWS_LIST_SUCCESS:
      return {...state, newsList: action.data, loading: false, error: null};
    case types.FETCH_NEWS_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.READ_NEWS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.READ_NEWS_ITEM_SUCCESS:
      return {...state, newsItem: action.data, itemLoading: false, error: null};
    case types.READ_NEWS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.CREATE_NEWS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.CREATE_NEWS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.CREATE_NEWS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.UPDATE_NEWS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.UPDATE_NEWS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.UPDATE_NEWS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.DELETE_NEWS_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.DELETE_NEWS_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.DELETE_NEWS_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
