import * as types from '../actionTypes';

const initialState = {
  guidesList: [],
  guidesItem: {},
  loading: false,
  itemLoading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_GUIDES_LIST_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_GUIDES_LIST_SUCCESS:
      return {...state, guidesList: action.data, loading: false, error: null};
    case types.FETCH_GUIDES_LIST_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.READ_GUIDES_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.READ_GUIDES_ITEM_SUCCESS:
      return {...state, guidesItem: action.data, itemLoading: false, error: null};
    case types.READ_GUIDES_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.CREATE_GUIDES_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.CREATE_GUIDES_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.CREATE_GUIDES_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.UPDATE_GUIDES_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.UPDATE_GUIDES_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.UPDATE_GUIDES_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    case types.DELETE_GUIDES_ITEM_REQUEST:
      return {...state, itemLoading: true, error: null};
    case types.DELETE_GUIDES_ITEM_SUCCESS:
      return {...state, itemLoading: false, error: null};
    case types.DELETE_GUIDES_ITEM_FAILURE:
      return {...state, itemLoading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
